import Head from 'next/head';
import styled from 'styled-components';

const WebWrapper = styled.main`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 55px);

  padding-top: 20vh;
  padding-bottom: 10vh;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  .background-banner {
    width: 100%;
    height: 100vh;

    background: url('/backgrounds/gradient.svg') no-repeat fixed right top;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default function Web() {
  return (
    <WebWrapper>
      <Head>
        <title>Airdropr</title>
      </Head>
      <div className="background-banner">
        <h1>Airdropr</h1>
      </div>
    </WebWrapper>
  );
}
